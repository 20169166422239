import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Collapse, LinearProgress } from "@mui/material";
import { CheckRounded, InfoOutlined } from "@mui/icons-material";
import { flatten, isEqual, uniqWith } from "lodash";
import React, { useState } from "react";
import tw from "twin.macro"; // eslint-disable-line

import { JimButton, TilePrim } from "../components/util-components";
import { formatDE } from "../utils/util-functions";
import withRoles from "../utils/with-roles";

const LIST_POOLS = gql`
  query ListPools {
    listPools {
      id
      title
      facility {
        id
        name
      }
      workers {
        id
        first_name
        last_name
      }
    }
  }
`;

const LIST_FACILITIES = gql`
  query ListFacilities {
    listFacilities {
      id
      users {
        role
        user {
          id
        }
      }
    }
  }
`;

const ADD_FACILITY_WORKER = gql`
  mutation AddFacilityWorker($facility: ID!, $user: ID!) {
    addFacilityWorker(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const CollTitle = tw.div`bg-white py-1 px-2 rounded-md shadow-md text-base text-prim-500 cursor-pointer`;

const Scripts = () => {
  const [listPools, { data: poolsListData, loading: poolsListLoading }] =
    useLazyQuery(LIST_POOLS);
  const { data: facilitiesListData, loading: facilitiesListLoading } =
    useQuery(LIST_FACILITIES);
  const [addFacilityWorker, { loading: addWorkerLoading }] =
    useMutation(ADD_FACILITY_WORKER);

  const [adminConsole, setAdminConsole] = useState([]);
  const addToConsole = msg => {
    const newContent = [...adminConsole];
    newContent.push(`[${formatDE(new Date(), "HH:mm:ss")}] - ${msg}`);
    setAdminConsole(newContent);
  };

  const [scriptSel, setScriptSel] = useState(0);

  const [facilitiesByID, setFacilitiesByID] = useState({});

  const handleClickCollapse = num => {
    if (num === scriptSel) {
      setScriptSel(null);
    } else {
      setScriptSel(num);
    }
  };

  const handleListFacilities = () => {
    const facsByID = {};
    facilitiesListData.listFacilities.forEach(fac => {
      if (!facsByID[fac.id]) {
        facsByID[fac.id] = fac;
      }
    });
    console.log(facsByID);
    setFacilitiesByID(facsByID);
  };

  const handleSingleAdd = (user, facility) => {
    addFacilityWorker({
      variables: {
        facility,
        user,
      },
    })
      .then(() => {
        addToConsole(`SUCCESS: added ${user} to ${facility}`);
        handleListFacilities();
      })
      .catch(err =>
        addToConsole(
          `ERROR (user-${user} to facility-${facility}: ${err.message}`
        )
      );
  };

  return (
    <>
      <div tw="p-1 my-2 rounded-md bg-gray-200">
        <CollTitle onClick={() => handleClickCollapse(0)}>
          add Users to Facilities by Pool
        </CollTitle>
        <Collapse in={scriptSel === 0}>
          <TilePrim title="add Users to Facilities by Pool">
            <div>
              <InfoOutlined tw="mr-2 text-lg" />
              <b>Update 0.1.3 (exp. 31.05.2021)</b>
              {` Model Facility now has field users that lists the users related to the facility and their respective role ("Worker", "ADMIN", or "CREATOR". This script gets all pools and then writes all users to their respective facility according to the pool's facility.`}
            </div>
            <div tw="flex items-center space-x-4">
              <JimButton
                disabled={poolsListData}
                variant="primary"
                tw="self-start"
                onClick={poolsListData ? null : () => listPools()}
              >
                {!poolsListData && !poolsListLoading && "Get Pools List"}
                {poolsListLoading && <div tw="animate-pulse">...</div>}
                {poolsListData && <CheckRounded />}
              </JimButton>
              <JimButton
                variant="primary"
                tw="self-start"
                onClick={handleListFacilities}
              >
                {facilitiesListLoading && <div tw="animate-pulse">...</div>}
                {facilitiesListData && "Check if users already in Facilities"}
              </JimButton>
            </div>
          </TilePrim>
          {poolsListData && poolsListData.listPools && (
            <TilePrim title="All Poolworkers">
              {uniqWith(
                flatten(
                  poolsListData.listPools.map(pool =>
                    pool.workers.map(worker => ({
                      user: worker.id,
                      facility: pool.facility
                        ? pool.facility.id
                        : "NO_FACILITY",
                      userName: `${worker.first_name} ${worker.last_name}`,
                      facilityName: pool.facility
                        ? pool.facility.name
                        : "NO_FACILITY",
                    }))
                  )
                ),
                isEqual
              ).map(workerFacilityObj => (
                <JimButton
                  tw="self-start"
                  variant="primary"
                  onClick={() =>
                    handleSingleAdd(
                      workerFacilityObj.user,
                      workerFacilityObj.facility
                    )
                  }
                  disabled={
                    (facilitiesByID[workerFacilityObj.facility] &&
                      facilitiesByID[workerFacilityObj.facility].users
                        .map(userObj => userObj.user.id)
                        .includes(workerFacilityObj.user)) ||
                    workerFacilityObj.facility === "NO_FACILITY"
                  }
                >
                  {facilitiesByID[workerFacilityObj.facility] &&
                  facilitiesByID[workerFacilityObj.facility].users
                    .map(userObj => userObj.user.id)
                    .includes(workerFacilityObj.user)
                    ? `${workerFacilityObj.userName} already in ${workerFacilityObj.facilityName}`
                    : `Add ${workerFacilityObj.userName} to ${workerFacilityObj.facilityName}`}
                </JimButton>
              ))}
            </TilePrim>
          )}
        </Collapse>
      </div>
      <div tw="p-1 my-2 rounded-md bg-gray-200">
        <CollTitle onClick={() => handleClickCollapse(1)}>next</CollTitle>
        <Collapse in={scriptSel === 1}>
          <TilePrim>skdjg</TilePrim>
        </Collapse>
      </div>
      <div tw="absolute bottom-0">
        <TilePrim title="Console" tw="max-h-56 p-2 md:max-w-4xl xl:max-w-5xl">
          <div tw="flex flex-col space-y-2 overflow-y-auto">
            {adminConsole.map(line => (
              <div>{line}</div>
            ))}
          </div>
          <div>{addWorkerLoading && <LinearProgress />}</div>
        </TilePrim>
      </div>
    </>
  );
};

export default withRoles(Scripts, "ADMIN");

// uniqWith(flatten(poolsList.listPools.map(pool => pool.workers.map(worker => ({user: worker.id, facility: pool.facility.id })))), isEqual)
